import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

//

const LiveChat = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div class="elfsight-app-c46088fc-83db-4d7b-ad3d-321aa6a187be"></div>
      <div className="visor_Counter">
        <div class="elfsight-app-708da63d-ed4c-480c-b467-75d3c0ef8b6e"></div>
      </div>
    </div>
  );
};

export default LiveChat;
