import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Directories = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div
        className="client-area client-area-pd bg-overlay pd-top-118"
        style={{ backgroundImage: `url(${rpdata?.gallery?.[2]})` }}
      >
        <div className="container">
          <div className="row justify-content-center text-lg-left text-center">
            <div className="col-lg-9">
              <div className="section-title style-white mb-0">
                <h6>{rpdata?.dbSlogan?.[3].slogan}</h6>
                <h2>{rpdata?.dbSlogan?.[4].slogan}</h2>
              </div>
            </div>
            <div className="col-lg-3 align-self-end text-lg-right mt-4 mt-lg-0 go-top">
              <Link className="btn btn-base" to="/contact">
                FREE ESTIMATE
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="client-slider bg-main style-two owl-carousel">
          <div className="item">
            <div className="thumb">
              <img src={"assets/img/client/2.png"} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directories;
