import React, { Component, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import Home from './components/home';
import About from './components/about';
import Services from './components/services';
import Gallery from './components/gallery';
import Contact from './components/contact';
import { GlobalDataContext } from './context/context';
import axios from 'axios'


function Root() {


    const id = "62f15916efe4ab04611d975b"
  const [rpdata, setrpdata] = useState({});


  //useEffect para peticion al servidor
  useEffect(() => {
    // console.log(“haciendo fetch”)
    async function fetchData() {
    
      try {
        const response = await axios({
          baseURL: "https://paginasserver.herokuapp.com/api",
          url: `/paginas/${id}`,
          method: "get"
        });
        console.log(response.data)
        //se convierte la data en el objeto
        setrpdata(JSON.parse(JSON.stringify(response.data)));
        // console.log(rpdata)
     
      } catch (error) {
        console.log(error);
     
      }
    };
    fetchData();
  }, [])


        return(
            <>
            <GlobalDataContext.Provider value={{ rpdata }}>
                <Router basename="/">
	                <div>
	                <Switch>
                        <Route exact path="/" component={Home} />
                        <Route  path="/about" component={About} />
                        <Route  path="/services" component={Services} />
                        <Route  path="/gallery" component={Gallery} />
                        <Route  path="/contact" component={Contact} />
	                </Switch>
	                </div>
                </Router>
                </GlobalDataContext.Provider>
                </>
        )
    }

export default Root;

ReactDOM.render(<Root />, document.getElementById('mingrand'));
